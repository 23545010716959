/// <reference path="../../../typings/jquery/jquery.d.ts"/>
/// <reference path="../../../typings/materialize-css/materialize-css.d.ts"/>

class Users {
    private lv: LifeVine;
    private list;
    private userListUl;
    private userForm;
    private currentId;
    private dependents;
    private locations;

    constructor(lv: LifeVine) {
        this.lv = lv;
        this.userForm = jQuery('#form_user');
        this.userListUl = jQuery('#user_list');
        this.dependents = this.userForm.find('#user_dependents');
        this.locations = this.userForm.find('#user_locations');

        this.userForm.find('select').material_select();

        this.userListUl.on('click', 'a', event => {
            event.preventDefault();
            event.stopPropagation();

            this.newUser(); // Clear the form

            let id = $(event.target).closest('a').data('id');
            this.getUser(id);
        });

        this.refreshList();
        this.newUser();

        this.userForm.on('submit', event => {
            event.preventDefault();
            event.stopPropagation();

            this.submitUser();
        });

        jQuery('#user_add').click(event => {
            event.preventDefault();
            event.stopPropagation();

            this.newUser();
        });

        this.userForm.find('#reset_password').click(event => this.resetPassword(event));


        this.dependents.find('#add_dependent').click(event => this.addDependent(event));
        this.dependents.on('click', 'a.edit-dependent', event => this.editDependent(event));
        this.dependents.on('click', 'a.delete-dependent', event => this.deleteDependent(event));

        this.locations.find('#add_location').click(event => this.addLocation(event));
        this.locations.on('click', 'a.make-primary', event => this.makePrimaryLocation(event));
        this.locations.on('click', 'a.edit-location', event => this.editLocation(event));
        this.locations.on('click', 'a.delete-location', event => this.deleteLocation(event));
    }


    public refreshList() {
        this.lv.users().get()
            .done(data => {
                this.list = data;
                this.renderList();
            });
    }

    private newUser() {
        this.currentId = null;
        let data = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            phone_text: false,
            user_type: 'sitter',
            active: true
        };

        this.userForm.find('#reset_password').prop('disabled', true);
        this.dependents.hide();
        this.locations.hide();
        setFormData(this.userForm, data);
        this.userForm.find('select').material_select();
        Materialize.updateTextFields();
    }

    private showUser(user) {
        this.currentId = user.id;
        setFormData(this.userForm, user);
        this.userForm.find('select').material_select();
        Materialize.updateTextFields();
        this.userForm.find('#reset_password').prop('disabled', false);

        if (user.user_type === 'client' || user.user_type === 'sitter') {
            if (user.user_type === 'client') {
                this.dependents.show();
                this.getDependents();
            }
            this.locations.show();
            this.getLocations();
        }
    }

    private showDependents(dependents) {
        this.dependents.find('.dependent').remove();
        dependents.forEach(dependent => {
            let content = '<div class="col s12 l6 dependent">'
                + `${dependent.first_name} ${dependent.last_name} - ${dependent.dob_display}<br>`
                + `<a href="#" data-dependent-id="${dependent.id}" class="edit-dependent">Edit</a>`
                + ` | <a href="#" data-dependent-id="${dependent.id}" class="delete-dependent">Delete</a>`
                + '</div>';
            this.dependents.append(content);
        });
    }

    private showLocations(locations) {
        this.locations.find('.location').remove();
        locations.forEach(location => {
            let content = '<div class="col s12 l6 location">'
                + location.line1 + '<br>'
                + (location.line2 ? location.line2 + '<br>' : '')
                + location.city + ' ' + location.state + ' ' + location.zip + '<br>'
                + (location.primary ?
                    'Primary' :
                    `<a href="#" data-location-id="${location.id}" class="make-primary">Make primary</a>`) + ' | '
                + `<a href="#" data-location-id="${location.id}" class="edit-location">Edit</a>`
                + (location.primary ?
                    '' :
                    ` | <a href="#" data-location-id="${location.id}" class="delete-location">Delete</a>`)
                + '</div>';
            this.locations.append(content);
        });
    }

    private submitUser() {
        let formData = getFormData(this.userForm);

        if (this.currentId) {
            this.lv.users().save(this.currentId, formData)
                .done(data => {
                    if (data.success === true) {
                        this.newUser();
                        this.refreshList();
                        Materialize.toast('User updated', 2000);
                    } else {
                        Materialize.toast('User not updated', 2000);
                        if (data.errors.active) {
                            Materialize.toast(data.errors.active, 2000);
                        }
                        this.setErrors(data.errors);
                    }
                });
        } else {
            this.lv.users().create(formData)
                .done(data => {
                    if (data.success === true) {
                        this.newUser();
                        this.refreshList();
                        Materialize.toast('User created', 2000);
                        this.getUser(data.id);
                    } else {
                        Materialize.toast('User not created', 2000);
                        this.setErrors(data.errors);
                    }
                });
        }
    }

    private resetPassword(event) {
        this.lv.users().resetPassword(this.currentId)
            .done(data => {
                if (data.success === true) {
                    alert('Email sent.');
                } else {
                    alert(data.errors);
                }
            });
    }

    private setErrors(errors) {
        jQuery.each(errors, (field, message) => {
            this.userForm.find(`[for="${field}"]`).attr('data-error', message);
            this.userForm.find(`#${field}`).removeClass('valid').addClass('invalid');
        });
        // errors.forEach()
        console.log(errors);
    }

    private renderList() {
        this.userListUl.html('');
        this.list.forEach(user => {
            this.userListUl.append(Users.createUserListItem(user));
        });
    }

    private static createUserListItem(user) {
        let content = '<i class="material-icons circle">person</i>' +
            `<span class="title">${user.first_name} ${user.last_name}</span>` +
            `<a href="#!" class="secondary-content" data-id="${user.id}"><i class="material-icons">edit</i></a>`;
        return jQuery('<li class="collection-item avatar"></li>').append(content);
    }

    private addDependent(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        new DependentModal(this.lv.users().dependents(this.currentId), null, () => {
            this.getDependents();
        });
    }

    private editDependent(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let dependentId = jQuery(event.target).data('dependent-id');
        new DependentModal(this.lv.users().dependents(this.currentId), dependentId, () => {
            this.getDependents();
        });
    }

    private deleteDependent(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let dependentId = jQuery(event.target).data('dependent-id');
        this.lv.users().dependents(this.currentId).remove(dependentId)
            .done(() => {
                this.getDependents();
            })
            .fail((xhr) => {
                Materialize.toast(xhr.responseJSON.error, 2000);
            });
    }

    private addLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        new LocationModal(this.lv.users().locations(this.currentId), null, () => {
            this.getLocations();
        });
    }

    private editLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let locationId = jQuery(event.target).data('location-id');
        new LocationModal(this.lv.users().locations(this.currentId), locationId, () => {
            this.getLocations();
        });
    }

    private deleteLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let locationId = jQuery(event.target).data('location-id');
        this.lv.users().locations(this.currentId).remove(locationId)
            .done(() => {
                this.getLocations();
            })
            .fail((xhr) => {
                Materialize.toast(xhr.responseJSON.error, 2000);
            });
    }

    private makePrimaryLocation(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let locationId = jQuery(event.target).data('location-id');
        this.lv.users().locations(this.currentId).makePrimary(locationId)
            .done(() => {
                Materialize.toast('Primary location updated', 3000);
                this.getLocations();
            });
    }

    private getDependents() {
        this.lv.users().dependents(this.currentId).get()
            .done(dependents => {
                this.showDependents(dependents);
            });
    }

    private getLocations() {
        this.lv.users().locations(this.currentId).get()
            .done(locations => {
                this.showLocations(locations);
            });
    }

    private getUser(userId) {
        this.lv.users().get(userId)
            .done(user => {
                this.showUser(user);
            });
    }
}

window.app.page('page-users', () => {
    return params => {
        if (window.controllers.users) {
            window.controllers.users.refreshList();
        } else {
            window.controllers.users = new Users(window.lifeVine);
        }
    };
});
